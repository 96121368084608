<template>
  <div>
    <div class="flex flex-col w-full py-2 text-center">
      <h1 class="text-2xl font-bold text-gray-700 sm:text-3xl">
        Iniciar sesión
      </h1>
    </div>
    <div>
      <div class="flex flex-col py-2 text-left">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          hide-required-asterisk>
          <el-form-item
            label="Correo Electrónico" prop="email" autocomplete="off">
            <el-input v-model="loginForm.email" />
          </el-form-item>
          <el-form-item label="Contraseña" prop="password">
            <el-input
              :type="showPassword ? 'text' : 'password'"
              v-model="loginForm.password"
              autocomplete="off">
              <span
                class="el-input__icon cursor-pointer text-sm hover:cursor-pointer flex items-center"
                :class="showPassword ? 'text-red-400 hover:text-red-400': 'text-purple-500 hover:text-purple-400'"
                slot="suffix"
                @click="showPassword = !showPassword">
                 <i :class="showPassword ? 'text-xl bx bx-show text-red-500': 'text-xl bx bx-hide'"></i>
              </span>
            </el-input>
          </el-form-item>
          <el-form-item class="-mt-4">
            <div>
              <router-link class="text-purple-500" to="/forgot-password">
                ¿Olvidaste tu contraseña?
              </router-link>
            </div>
          </el-form-item>
        </el-form>
        <div class="py-2">
          <span v-if="error" class="text-red-500">{{ error }}</span>
        </div>
        <div class="">
          <sa-button
            class="rounded text-bold"
            custom-class="w-full"
            type="primary"
            v-loading="buttonLoading"
            element-loading-background="#DDD6FE"
            element-loading-spinner=""
            @click="login">
              Iniciar sesión
          </sa-button>
        </div>
        <div class="py-6 text-center">
          <span>
            ¿Aún no tienes cuenta?
            <router-link
              to="/register"
              class="text-purple-500 hover:cursor-pointer">
              Regístrate
            </router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SignService from '@/services/SignService';
import error from '@/mixins/error';
import AdminService from '@/services/AdminService';

export default {
  name: 'LoginForm',

  mixins: [
    error,
  ],

  data() {
    return {
      buttonLoading: false,
      error: null,
      showPassword: false,
      loginForm: {
        email: null,
        password: null,
      },
      loginRules: {
        email: [ { required: true, type: 'email', message: 'Captura tu email', trigger: 'change' } ],
        password: [ { required: true, message: 'Captura tu contraseña', trigger: 'change' } ],
      }
    };
  },
  methods: {
    async login() {
      this.error = null;
      this.$refs['loginForm'].validate(async (isFormValid) =>{
        if (!isFormValid)
          return false;
        const authData = await this.authenticate();
        if (authData == null)
          return false;
        if (authData?.error === 'EmailVerification') {
          this.$swal.fire({
            title: 'Verificación Fallida',
            icon: 'error',
            text: 'Ocurrio un error al hacer la comprobacion de tu correo electrónico',
            footer: 'No recibiste correo de verificación?&nbsp;<a class="text-purple-500" href="/resend-verification-email">Reenviar</a>'
          });
          return false;
        }
        await this.onLogin();
        const businessId = this.$store.state.business.id;
        await this.$router.replace({ name: businessId ? 'home.index' : 'onboarding.index' });
      });
    },
    async onLogin() {
      await AdminService.MyBusiness.read();
    },
    async authenticate() {
      try {
        this.buttonLoading = true;
        return await SignService.login(this.loginForm);
      } catch(error) {
        this.error = this.getErrorMessage(error);
        return null;
      } finally {
        this.buttonLoading = false;
      }
    },
  },
};
</script>
